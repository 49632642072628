@font-face {
  font-family: "AktivGrotesk-Regular";
  src: url("fonts/AktivGroteskRg.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html {
  width: 100%;
  overflow-x: hidden;
  background-color: #000000;
  font-family: AktivGrotesk-Regular !important;
  position:relative;
}

body {
  font-family: AktivGrotesk-Regular !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
  background-color: #000000;
  position:relative;
}

.g-card {
  margin: 20px;
}


.g-card-image {
  border-radius: 10px;
  width: 300px;
  height: 480px;
  box-shadow: 0px 0px 3px 1px #ccc;
}


.g-card-info {
  margin-top: 10px;
  min-height: 100px;
}


.g-card-title {
  font-size: 24px;
  margin: 0px;
}


.g-card-sub-title {
  font-size: 16px;
  margin: 0px;
}


.success-msg {
  color: #57A773;
  padding: 10px 15px;
}


.err-msg {
  color: #EE6352;
  padding: 10px 15px;
}